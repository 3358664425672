
.add-customer-information {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 20px;
    .create-content {
        flex: 1;
        .create-content-top {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            // .create-content-left {
            // }
            .create-content-right {
                display: flex;
                align-items: center;
                .label-name {
                    min-width: 124px;
                }
            }
        }
        .evaluate-box {
            display: flex;
            width: 150px;
            ::v-deep .el-rate__icon {
                font-size: 19px;
            }
        }
        .add-footer {
            margin-top: 20px;
        }
        .success-content {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-top: 140px;
            .text {
                font-size: 16px;
                color: #333;
                font-weight: bold;
            }
        }
    }

    /*.customer-scrollbar {*/
    /*    height: 100%;*/
    /*    ::v-deep .el-scrollbar__wrap {*/
    /*        overflow-x: hidden;*/
    /*    }*/
    /*}*/
    ::v-deep .el-input-number {
        .el-input__inner {
            text-align: left;
        }
    }
    ::v-deep .el-textarea__inner {
        height: 80px;
        resize: none;
    }

    /*::v-deep .el-dialog__body {*/
    /*    height: calc(100vh - 360px);*/
    /*    padding: 20px 20px;*/
    /*}*/

    .bottom-btn {
        text-align: center;
        margin-bottom: 50px;
    }

    .dialog-footer {
        text-align: center;
    }
}
